import React from "react";
import { Routes, Route } from 'react-router-dom';
import MainPage from "./pages/mainPage.jsx";
import Me from "./components/combs/me.jsx";
import Contact from "./components/combs/contact.jsx";
import Chatbot from "./components/combs/chatbot.jsx";
import ProjectsPage from "./components/combs/projectsPage.jsx";
import Header from "./components/header.jsx";

const App = () => {
  return (
    <Routes>
      <Route index path="/" element={<MainPage />} />
      <Route path="/me" element={<>
        <Header />
        <Me />
      </>} />
      <Route path="/projects/*" element={<>
        <Header />
        <ProjectsPage />
      </>} />
      <Route path="/chatbot" element={<>
        <Header />
        <Chatbot />
      </>} />
      <Route path="/contact" element={<>
        <Header />
        <Contact />
      </>} />
    </Routes>
  );
};

export default App;
