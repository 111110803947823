import React from 'react';
import { Helmet } from 'react-helmet-async';

const Me = () => {

  const personData = {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Martin Kuchar",
    "url": "https://www.beehive-techhub.com/",
    "sameAs": [
      "https://www.linkedin.com/in/kucharmartin",
      "mailto:kucharmartin0@gmail.com",
      "tel:+12365120228"
    ]
  };

  return (
    <div className="app">
      <Helmet>
        <title>Martin Kuchar Resume - Software Development</title>
        <script type="application/ld+json">{JSON.stringify(personData)}</script>
      </Helmet>
      <div className="my-history-section-wrapper">
        <div className="my-history-section">
          <div className="my-history-content">
            <div className="my-history-card-section">
              <img src={process.env.PUBLIC_URL + '/assets/images/martin-picture.png'} alt="Profile" />
              <h2>Martin Kuchar</h2>
              <div>
                <p>
                  As a result-oriented and proactive professional with a strong work ethic,
                  I am committed to staying up to date with technology trends.
                  I am excited to explore opportunities that allow me to leverage my expertise to create impactful solutions.
                </p>
                <p>Thank you for your interest.</p>
              </div>
            </div>
            <div>
              <div className="section-title">Experience</div>
              <ul>
                <li>
                  <b><strong>Project Lead - Software Developer</strong> at QuoteMedia, Inc., Vancouver, BC, Canada (Sep 2022 - Present)</b>
                  <div className="position-description">
                    - Successfully finalized a proof of concept for a stock market analysis Excel add-in utilizing a streaming solution API, which transitioned into a full-fledged project based on its value and potential.
                    <br />
                    - Demonstrated project management skills by overseeing the project, prioritizing scalability, long-term maintainability, and simplicity.
                    <br />
                    - Led a team in the development and implementation of the stock market analysis Excel add-in, ensuring seamless integration with the streaming solution API.
                  </div>
                </li>
                <li>
                  <b><strong>Software Developer</strong> at QuoteMedia, Inc., Vancouver, BC, Canada (Mar 2021 - Sep 2022)</b>
                  <div className="position-description">
                    - Contributed to the development, maintenance, and modification of web microservices within the company's microservice system.
                    <br />
                    - Engaged in code review and peer review meetings to provide constructive feedback and improve the overall design and code quality.
                    <br />
                    - Applied expertise in Microservices, JavaServer Pages (JSP), Hibernate, Spring Boot, Java, and Spring Framework to deliver robust and scalable solutions.
                  </div>
                </li>
                <li>
                  <b><strong>Service Engineer</strong> at Rosim AS, Oslo, Norway (Jun 2018 - Nov 2019)</b>
                  <div className="position-description">
                    - Ensured accurate documentation and reports by maintaining comprehensive records through online service documentation systems.
                    <br />
                    - Managed all aspects of on-site installation, adjustment, maintenance, and testing tasks for flow measuring sensors.
                    <br />
                    - Conducted thorough checks to verify the functionality of flow sensors and effectively troubleshooted any issues that arose.
                  </div>
                </li>
              </ul>
              <div className="section-title">Academic profile</div>
              <ul>
                <li>
                  <b><strong>Diploma Course, Mobile App and Web Development</strong> at CICCC - Cornerstone International Community College of Canada (Jan 2020 - Jan 2021)</b>
                </li>
                <li>
                  <b><strong>Master's Degree, Environmental Engineering Technology/Environmental Technology</strong> at University of Zilina (2013 - 2018)</b>
                  <div className="position-description">
                    - Successfully graduated Master’s degree. Gained theoretical knowledge of energy systems, renewable energy sources, and HVAC projection.
                    <br />
                    - Final thesis consisted of researching the effects of the airflow in the boiler. Used the advanced PIV method to measure the speed and direction of air. These factors influence the fluency of combustion, production of emission. The solution (airflow division) was successful, optimizing combustion in the boiler, reducing the production of emission, and saving energy.
                  </div>
                </li>
                <li>
                  <b><strong>Environmental Engineering Technology/Environmental Technology</strong> at Czech Technical University in Prague (2016 - 2017)</b>
                  <div className="position-description">
                    - Successfully completed the Erasmus program. Was an exchange student through the Erasmus program in Prague, where attended the Czech Technical University of Prague. Spent one semester there. Studied different software development languages such as Python, measuring systems in environmental technic, and HVAC programs. It was a great opportunity to gain experience in the software engineering fields.
                  </div>
                </li>
              </ul>
              <div className="section-title">Licenses & Certifications</div>
              <ul>
                <li>
                  <b><strong>Understanding Financial Markets</strong> (Coursera, Issued Sep 2022, Credential ID: PNBHD9FZB2EN)</b>
                </li>
              </ul>
              <div className="section-title">Volunteering</div>
              <ul>
                <li>
                  <b><strong>Coding Mentor/Teaching Assistant</strong> at CICCC - Cornerstone International Community College of Canada (Sep 2020 - Feb 2021)</b>
                  <div className="position-description">
                    - Reviewed student's code, graded assignments, and provided feedback with specific advice to their work.
                    <br />
                    - Mentored students to learn and improve their programming and object-oriented thinking.
                  </div>
                </li>
              </ul>
            </div>
            <div className="my-history-card-section">
              <h2>Skills</h2>
              <ul>
                <li>
                  <strong>Programming Languages:</strong>
                  <br />
                  - JavaScript, Java, Swift, Objective-C, Kotlin, Python, SQL
                </li>
                <li>
                  <strong>Backend Development:</strong>
                  <br />
                  - Microservices, Node.js, Spring Boot, Spring Framework, Hibernate
                </li>
                <li>
                  <strong>Web Development:</strong>
                  <br />
                  - Node.js & Express, HTML5 & CSS3, React.js
                </li>
                <li>
                  <strong>Version Control:</strong>
                  <br />
                  - Git (Bitbucket, GitHub)
                </li>
                <li>
                  <strong>Cloud computing:</strong>
                  <br />
                  - AWS, Cloudflare
                </li>
                <li>
                  <strong>Other Skills:</strong>
                  <br />
                  - Object-Oriented Programming (OOP) principles, RESTful APIs, Web services, Microservice architecture,
                  MVC architecture, Design patterns, Software debugging and troubleshooting, Problem-solving and analytical thinking
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Me;
