import React from 'react';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
  const navigate = useNavigate();

  const projects = [
    { id: 1, title: 'Portfolio Website', description: 'My personal portfolio website', link: 'portfolio', image: process.env.PUBLIC_URL + '/assets/images/my-portfolio-image.png' },
    { id: 2, title: 'Excel Add-in', description: 'Stock market analysis Excel add-in', link: 'excelAddin', image: process.env.PUBLIC_URL + '/assets/images/excel-addin.png' },
    { id: 3, title: 'Charting Service', description: 'Service that processes REST requests and return chart related stock data', link: 'chartingService', image: process.env.PUBLIC_URL + '/assets/images/candle-chart.png' },
  ];

  const handleProjectClick = (link) => {
    navigate(`/projects/${link}`);
  };

  return (
    <div className="projects-wrapper">
      <h1 className="projects-title">My Projects</h1>
      <p className="projects-intro">Step into my digital realm!</p>
      <div className="projects-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card" onClick={() => handleProjectClick(project.link)}>
            <img src={project.image} alt={project.title} className="project-image" />
            <h2>{project.title}</h2>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
