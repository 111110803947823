import React from "react";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="centered-header">
      <div className="nav-link" onClick={() => navigate('/')}>
        <img src={process.env.PUBLIC_URL + '/assets/images/beehive-home-icon.png'} alt="Logo" loading="lazy" className="logo-image" />
      </div>
    </header>
  );
};

export default Header;
