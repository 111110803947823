import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';

function Honeycomb() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const martinData = {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Martin Kuchar",
    "url": "https://www.beehive-techhub.com/",
    "sameAs": [
      "https://www.linkedin.com/in/kucharmartin",
      "mailto:kucharmartin0@gmail.com",
      "tel:+12365120228"
    ]
  };

  return (
    <div className="app">
      <Helmet>
        <title>Martin Kuchar Resume - Software Development</title>
        <script type="application/ld+json">{JSON.stringify(martinData)}</script>
      </Helmet>
      <div className="flex-container">
        <div className="honeycombs">
          <div className="honeycomb">
            <div className="hexagon me" onClick={() => handleNavigate('/me')}>
              <img className='bee-image-in-honeycomb' src={process.env.PUBLIC_URL + '/assets/images/bee-icon.png'} loading="lazy" alt='bee icon' />
              <p className='bee-image-in-name'>Background</p>
            </div>
          </div>
          <div className="honeycomb">
            <div className="hexagon projects" onClick={() => handleNavigate('/projects')}>
              <h3>Projects</h3>
            </div>
            <div className="hexagon chatbot" onClick={() => handleNavigate('/chatbot')}>
              <p className='bee-image-in-name'>
                Chatbot
              </p>
              <p className='bee-image-in-name-smaller'>
                Powered by ChatGPT 🤖
              </p>
            </div>

          </div>
          <div className="honeycomb">
            <div className="hexagon contact" onClick={() => handleNavigate('/contact')}>
              <h3>Contact</h3>
            </div>
          </div>
        </div>
        <div className="intro-card-section">
          <div className="intro-card-section-markdown">
            <ReactMarkdown>
              {`
### Martin Kuchar

Hello! 

I'm a pragmatic software developer with a passion for both software development and finance.
I thrive on collaborating with talented individuals to create impactful products.

Let's connect and collaborate on meaningful projects together!
              `}
            </ReactMarkdown>
          </div>
          <img src={process.env.PUBLIC_URL + '/assets/images/martin-picture.png'} alt="Profile" />
        </div>
      </div>
    </div>
  );
}

export default Honeycomb;
