import React from 'react';

const ExcelAddin = () => {
  return (
    <div className="portfolio-project-page">
      {/* Section for the main content */}
      <div className="project-content-section">
        <h1>Stock market analysis Excel add-in</h1>
        <p className="projects-intro">The project that led to my promotion as the project leader!</p>
        <p>
          Successfully finalized a proof of concept for a stock market analysis Excel add-in utilizing a streaming solution
          API, which transitioned into a <em>full-fledged project</em> based on its value and potential. Demonstrated project
          management skills by overseeing the project, prioritizing scalability, long-term maintainability, and simplicity.
          Led the team in the development and implementation of the proposed features, and assure that the deadlines are met.
        </p>
      </div>

      {/* Section for the tools */}
      <div className="project-content-section tools-section">
        <h2>🎯 Architecture</h2>
        <img src={process.env.PUBLIC_URL + '/assets/images/architecture.png'} alt='project architecture' />
      </div>

      {/* Section for Technical Implementation */}
      <div className="project-content-section tools-section">
        <h2>🎯 Technical Implementation - Excel Add-In and Taskpane</h2>
        <p>
          For the development of the Stock Market Analysis Excel Add-In, I used the capabilities of the universal Office.js API.
          This API enabled seamless integration of custom functions, utilization of Excel objects, and execution of batch operations.
          The solution was designed to handle heavy data loads, especially when subscribing to the Streamer Solution API, ensuring optimal performance and responsiveness.
          <br />
          <br />
          The core of the project, the Taskpane, was built using technologies such as JavaScript, HTML, and CSS.
          The use of the observer pattern allowed for efficient handling of real-time data updates, ensuring that results were accurately reflected in the Excel environment.
          <br />
          <br />
          In order to achieve real-time data streaming, entitlement checks, and authentication, I used company's microservices ecosystem.
          This involved integrating with the Streamer Solution API for live market data, as well as leveraging authentication services for secure user access.
        </p>
        <h3>✅ Custom function - streaming invocation</h3>
        <h3>✅ Remote calls to company's microservices ecosystem</h3>
        <h3>✅ Efficient Observer Pattern for Real-Time Data Handling</h3>
        <h3>✅ Taskpane UI</h3>
        <h3>✅ Responsive desgin</h3>
      </div>

      {/* Hosting */}
      <div className="project-content-section tools-section">
        <h2>🌐 Cloud Hosting and Distribution - Cloudflare and Microsoft Office Store</h2>
        <p>
          After the consideration of efficiency, optimization and simplicity I made a decision that the most suitable will be hosting it on the static CDN.
          That's why the Stock Market Analysis Excel Add-In is hosted on Cloudflare's robust static content delivery network.
          <br />
          Furthermore, the project was successful published on the Microsoft Office Store. This accomplishment underscores the quality and value of the project, and its place in the digital marketplace.
          <br />
          <br />
          <b><em>However, due to company confidentiality and ownership, I am unable to provide a public link to the add-in itself.</em></b>
        </p>
        <h3>✅ Cloudflare Static Content Delivery Network Hosting</h3>
        <h3>✅ Microsoft Office Store Publication</h3>
      </div>
    </div>
  );
};

export default ExcelAddin;
