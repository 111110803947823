import React from 'react';
import PortfolioProject from "./projects/portfolioProject.jsx";
import Projects from "./projects/projects.jsx";
import ExcelAddin from './projects/excelAddin.jsx';
import ChartingService from './projects/chartingService.jsx';
import { Route, Routes } from "react-router-dom";

const ProjectsPage = () => {

  return (
    <div className="app">
      <div className="projects-page">
        <Routes>
          <Route index element={<Projects />} />
          <Route path="/portfolio" element={<PortfolioProject />} />
          <Route path="/excelAddin" element={<ExcelAddin />} />
          <Route path="/chartingService" element={<ChartingService />} />
        </Routes>
      </div>
    </div>
  );
};

export default ProjectsPage;
