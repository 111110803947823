import React from "react";
import Honeycomb from "../components/mainpage/honeycomb.jsx";

const MainPage = () => {
  return (
    <div className="app-full">
      <Honeycomb />
    </div>
  );
};

export default MainPage;
