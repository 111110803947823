import React from 'react';

const PortfolioProject = () => {
  return (
    <div className="portfolio-project-page">
      {/* Section for the main content */}
      <div className="project-content-section">
        <h1>Portfolio Project</h1>
        <p className="projects-intro">Demonstration of my full stack development skills, and more!</p>
        <p>
          Welcome to my Portfolio Project! I aimed to create a portfolio website that showcases my skills in software
          development and cloud engineering. I leveraged various AWS cloud services to design an architecture that not
          only presented my work but also demonstrated my ability to deploy a scalable and secure web application.
        </p>
      </div>

      {/* Section for the tools */}
      <div className="project-content-section tools-section">
        <h2>☁️ AWS</h2>
        <p>
          This project reinforced my commitment to learning and mastering cloud technologies, showcasing my technical
          expertise in deploying scalable and secure web applications, a valuable asset in today's cloud-centric world.
        </p>
        <h3 className="tools-header">✅ Amplify - Frontend Framework:</h3>
        <p>
          I chose AWS Amplify as the frontend framework for its comprehensive set of tools, simplifying the development
          of modern and responsive user interfaces. Easy to set up continuous deployment, and integration with git.
        </p>

        <h3 className="tools-header">✅ S3 - Storage:</h3>
        <p>
          Amazon S3 hosted my static assets, ensuring high availability, reliability, and fast loading times.
        </p>

        <h3 className="tools-header">✅ Lambda - Serverless Method for API Security:</h3>
        <p>
          This needed to be done mainly for a chatbot part of the application.
          Using AWS Lambda, I created a secure backend for my website's API, integrating with Amazon API Gateway to
          protect endpoints and user data.
        </p>

        <h3 className="tools-header">✅ API Gateway - Efficient Data Routing:</h3>
        <p>
          Amazon API Gateway efficiently managed API traffic, routing requests to Lambda functions and providing a clean
          and straightforward interface for backend services.
        </p>

        <h3 className="tools-header">✅ CloudWatch Logs - Monitoring and Troubleshooting:</h3>
        <p>
          CloudWatch Logs monitored performance, providing insights into my website's health and user experience, enabling
          proactive maintenance.
        </p>
      </div>
      {/* Section for SEO */}
      <div className="project-content-section tools-section">
        <h2>🎯 SEO</h2>
        <p>
          Implementing SEO in my portfolio project was a crucial step to enhance its online visibility and reach.
          By optimizing SEO I ensured that my portfolio stands out in search engine results.
          This strategic approach was vital in attracting relevant traffic and potential employers or clients,
          showcasing my professionalism and attention to detail in web development.
        </p>

        <h3>✅ Meta Description</h3>
        <h3>✅ Structured Data</h3>
        <h3>✅ URL Structure</h3>
        <h3>✅ Image Optimization</h3>
        <h3>✅ Mobile-Friendly Design</h3>
        <h3>✅ Social Media Sharing</h3>
      </div>
      {/* Section for caching */}
      <div className="project-content-section tools-section">
        <h2>⚡ Client-Side Caching for Improved Performance</h2>
        <p>
          As part of my efforts to provide a faster and more responsive user experience, I implemented client-side caching
          techniques into my portfolio website. By leveraging browser caching, I ensured that certain resources are stored
          locally in users' browsers.
        </p>
        <h3 className="tools-header">✅ Browser Caching for Images</h3>
        <p>
          To speed up the loading of images, I implemented browser caching for images using the `amplify.yml` configuration.
        </p>
      </div>
      {/* Section for SEO */}
      <div className="project-content-section tools-section">
        <h2>📊 Google Analytics</h2>
        <p>
          My intrest in data-driven development led me to integrate google analytic with my portfolio website. 
          Analyzing and considering the data has always been a great part of my career since it's a user who creates the best product!        
        </p>
        <h3>✅ Demographics details</h3>
        <h3>✅ User behaviour records</h3>
        <h3>✅ User acquisition</h3>
        <p>
          The technical integration of google analytics is quite an easy process, however, 
          the overall understanding of the valuable data is continuous learning and reflective practice.
        </p>
      </div>
    </div>
  );
};

export default PortfolioProject;
