import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import ScrollToTop from './components/scrollToTop';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <ScrollToTop />
      <App />
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();
