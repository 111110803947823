import React from 'react';
import { FaLinkedin, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  const contactData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Martin Kuchar",
    "email": "kucharmartin0@gmail.com",
    "telephone": "+1 (236) 512-0228",
    "url": "https://www.linkedin.com/in/kucharmartin/",
  };

  return (
    <div className="app">
      <Helmet>
        <title>Martin Kuchar contact - gmail, linkedin, phone, address</title>
        <script type="application/ld+json">{JSON.stringify(contactData)}</script>
      </Helmet>
      <div>
        <div className="contact-container">
          <h2>Contact Me</h2>
          <h3>
            📌 Vancouver, BC
          </h3>
          <div className="contact-methods">
            <div className="contact-method">
              <FaLinkedin className="contact-icon-blue" />
              <a href="https://www.linkedin.com/in/kucharmartin/" target="_blank" rel="noopener noreferrer">
                LinkedIn - Martin Kuchar
              </a>
            </div>
            <div className="contact-method">
              <FaEnvelope className="contact-icon-red" />
              <a href="mailto:kucharmartin0@gmail.com">kucharmartin0@gmail.com</a>
            </div>
            <div className="contact-method">
              <FaPhone className="contact-icon-black" />
              <a href="tel:+12365120228">+1 (236) 512-0228</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
