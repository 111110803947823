import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Constants from "../../constants/constants.json";

const Chatbot = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [isChatbotBusy, setIsChatbotBusy] = useState(false);
  const [suggestionMessages, setSuggestionMessages] = useState([]);
  const chatMessagesRef = useRef(null);

  const navigate = useNavigate();

  const chatbotProductData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Chatbot powered by ChatGPT (OpenAI)",
    "description": "An intelligent chatbot powered by ChatGPT from OpenAI. Engage with the bot to get responses generated by advanced AI language models.",
    "url": "https://www.beehive-techhub.com/",
    "brand": {
      "@type": "Brand",
      "name": "Beehive Techhub"
    },
    "offers": {
      "@type": "Offer",
      "price": "0.00",
      "priceCurrency": "USD",
      "availability": "TBD"
    }
  };

  useEffect(() => {
    // Scroll to the bottom after updating chatMessages
    const chatMessagesContainer = chatMessagesRef.current;
    if (chatMessagesContainer) {
      chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
    }

    // TODO: Set initial suggestion messages - change one of them to contact 
    const initialSuggestionMessages = [
      'Tell me something about Martin Kuchar.',
      'What is Martin Kuchar\'s biggest accomplishment?',
      'How do I contact Martin Kuchar for collaboration?'
    ];
    setSuggestionMessages(initialSuggestionMessages);
  }, [chatMessages]);

  const handleSuggestionClick = async (suggestion) => {
    // Send the suggestion as a message
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: suggestion },
    ]);

    // Clear the user input field
    setUserInput('');

    // Set chatbot to busy state
    setIsChatbotBusy(true);

    // Send the suggestion to the server and get the chatbot's response
    const chatbotMessage = await sendMessageToServer(suggestion);

    // Set chatbot back to not busy state
    setIsChatbotBusy(false);

    // Display the chatbot's response
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: 'chatbot', content: chatbotMessage },
    ]);
  };

  const sendMessageToServer = async (message) => {
    try {
      const response = await fetch(Constants.api.chatbotURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: message }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.response;
    } catch (error) {
      console.error('Error sending message to server:', error);
      return 'Something went wrong, try again.';
    }
  };

  const handleUserMessage = async () => {
    const message = userInput.trim();
    if (!message) return;

    setChatMessages((prevMessages) => [...prevMessages, { role: 'user', content: message }]);
    setUserInput('');

    // Set chatbot to busy state
    setIsChatbotBusy(true);

    const chatbotMessage = await sendMessageToServer(message);

    // Set chatbot back to not busy state
    setIsChatbotBusy(false);

    setChatMessages((prevMessages) => [...prevMessages, { role: 'chatbot', content: chatbotMessage }]);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUserMessage();
    }
  };

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };

  const handleContactClick = () => {
    navigate(`/contact`);
  };

  const clickableLabelStyle = {
    cursor: 'pointer',
    color: "gray",
    fontStyle: 'italic',
  };

  return (
    <div className='app'>
      <div className="project-content-section">
        <h1>Chatbot 🤖</h1>
        <p className="projects-intro">Ask anything about Martin!</p>
      </div>
      <div className="chatbot-flex-container">
        <Helmet>
          <title>Beehive Techhub - Chatbot powered by ChatGPT (OpenAI)</title>
          <script type="application/ld+json">{JSON.stringify(chatbotProductData)}</script>
        </Helmet>
        <div className="chatbot-card">
          <div className="chat-container">
            <div ref={chatMessagesRef} className="chat-messages">
              {chatMessages.map((message, index) => (
                <div key={index} className={`message ${message.role}`}>
                  {message.content}
                </div>
              ))}
              {isChatbotBusy && (
                <div className="loading-indicator">
                  <span>Loading...</span>
                </div>
              )}
            </div>
            <div className="suggestion-messages">
              {suggestionMessages.map((message, index) => (
                <div
                  key={index}
                  className="suggestion-bubble"
                  onClick={() => handleSuggestionClick(message)} // Call handleSuggestionClick when clicked
                >
                  {message}
                </div>
              ))}
            </div>
            <div className="user-input-container">
              <input
                type="text"
                name="inputToChatbot"
                value={userInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type a message..."
              />
              <button className="submit-button" onClick={handleUserMessage}>
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
        <div className="chatbot-content-section tools-section">
          <div className='card-section-chatbot'>
            <p>
              By implementing this advanced chatbot, I showcase my expertise in developing and fine-tuning chatbots that integrate with any technology.
              <br/>
              I'm ready to improve your digital experience and empower your business with intelligent AI solutions.
            </p>
            <br/>
            <p>
              Let's explore all the possibilities together!
            </p>
          </div>
          <div className={`disclaimer ${showDisclaimer ? 'show' : ''}`}>
            <h6>DISCLAIMER</h6>
            <p>
              This chatbot powered by ChatGPT is fine-tuned to some extent, however, it may produce inaccurate information about people, places, or facts.
              If you see an inaccurate response about Martin Kuchar - <span className="clickable-label"
                onClick={handleContactClick} style={clickableLabelStyle}>contact Martin</span>, please.
            </p>
            <p>
              Thank you for your understanding!
            </p>
          </div>
          {/* <button className={`show-more-button ${showDisclaimer ? 'rotate' : ''}`} onClick={toggleDisclaimer}>
            {showDisclaimer ? 'Show Less' : 'Show More'}
            <span className={`arrow ${showDisclaimer ? 'rotate' : ''}`}>&#9660;</span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
