import React from 'react';

const ChartingService = () => {
  return (
    <div className="portfolio-project-page">
      <div className="project-content-section">
        <h1>Microservice - Charting Service</h1>
        <p className="projects-intro">Migrate deprecating microservice onto a newly built one in a Fintech Microservices Architecture</p>
        <p>
          Led the transformation and modernization of a charting service within the fintech company's microservices architecture. 
          The service, a Spring Boot application, played a pivotal role in processing REST requests for stock market data visualization. 
          <br/>
          Took ownership of the service, driving improvements across technology, performance, and user experience.
        </p>
        <p>
          Built the testing website to speed up regression testing and easy the QA's load.
          Reengineered the application's design pattern, replacing factory methods with dependency injection for enhanced modularity.
        </p>
        <p>
          Developed three new REST endpoints, expanding the service's capabilities and data offerings. 
          Significantly optimized performance by eliminating redundant database queries and leveraging the company's internal microservices for data retrieval.
        </p>
      </div>

      {/* Section for the tools */}
      <div className="project-content-section tools-section">
        <h2>🛠️ Technologies and Tools</h2>
        <ul>
          <li>Spring Boot</li>
          <li>RESTful APIs</li>
          <li>Dependency Injection</li>
          <li>Microservices Architecture</li>
          <li>Caching Strategies</li>
          <li>Thymeleaf</li>
        </ul>
      </div>

      {/* Section for Technical Implementation */}
      <div className="project-content-section tools-section">
        <h2>🚀 Technical Implementation</h2>
        <p>
          The migration to the new service involved refactoring the existing codebase, adopting modern practices and technologies.  
          The shift from factory design pattern to dependency injection brought flexibility and testability to the application's structure.
          Thymeleaf was introduced for server-side rendering, and dynamic content generation.
        </p>
        <p>
          Integration with the company's microservices ecosystem streamlined authentication and entitlement checks, enhancing security and data integrity. 
          This involved leveraging the power of RESTful APIs to efficiently manage user access and data validation.
        </p>
        <p>
          The utilization of caching mechanisms significantly reduced response times, ensuring rapid chart rendering for users.
        </p>
      </div>
    </div>
  );
};

export default ChartingService;
